import {addBreadcrumb, captureException} from '@sentry/react';
import {Value} from '@sinclair/typebox/value';
import {User} from 'firebase/auth';
import {Epic, ofType} from 'redux-observable';
import {
  combineLatest,
  concat,
  from,
  fromEventPattern,
  iif,
  merge,
  of,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {IEnterCompPayload} from '@chancer/common/lib/app/AppMessage';
import {
  ActionType,
  IAction,
  noOpAction,
} from '@chancer/common/lib/core/actions/Actions';
import {cleanUpLocalAnswers} from '@chancer/common/lib/core/actions/answers/AnswersActions';
import {
  logOut,
  onTemporaryUserScore,
} from '@chancer/common/lib/core/actions/auth/AuthActions';
import {
  announcementsError,
  announcementsRequest,
  announcementsResponse,
  competitionChatError,
  competitionChatRequest,
  competitionChatResponse,
  competitionCountsError,
  competitionCountsRequest,
  competitionCountsResponse,
  competitionStatusError,
  competitionStatusRequest,
  competitionStatusResponse,
  competitionVipsError,
  competitionVipsRequest,
  competitionVipsResponse,
  enterCompetitionError,
  enterCompetitionRequest,
  enterCompetitionResponse,
  fetchLeaderboard,
  fetchUpdateUserPrivate,
  fetchUser,
  fetchUserFollowing,
  getTempEntryError,
  getTempEntryRequest,
  getTempEntryResponse,
  groupError,
  groupRequest,
  groupResponse,
  inboxMessagesError,
  inboxMessagesRequest,
  inboxMessagesResponse,
  leaderboardConfigError,
  leaderboardConfigRequest,
  leaderboardConfigResponse,
  leaderboardError,
  leaderboardRequest,
  leaderboardResponse,
  onCompetitionError,
  onCompetitionRequest,
  onCompetitionResponse,
  onCompetitionsError,
  onCompetitionsRequest,
  onCompetitionsResponse,
  onQuestionsError,
  onQuestionsRequest,
  onQuestionsResponse,
  resetCompetition,
  tempEntryError,
  tempEntryRequest,
  tempEntryResponse,
  updateUserError,
  updateUserPrivateError,
  updateUserPrivateRequest,
  updateUserPrivateResponse,
  updateUserRequest,
  updateUserResponse,
  userEntriesError,
  userEntriesRequest,
  userEntriesResponse,
  userError,
  userFollowingError,
  userFollowingRequest,
  userFollowingResponse,
  userPrivateError,
  userPrivateRequest,
  userPrivateResponse,
  userRequest,
  userResponse,
  vendorCompCountsError,
  vendorCompCountsRequest,
  vendorCompCountsResponse,
  vendorLiveLeaderboardsError,
  vendorLiveLeaderboardsRequest,
  vendorLiveLeaderboardsResponse,
  vendorNewsError,
  vendorNewsRequest,
  vendorNewsResponse,
  vendorQuestionsError,
  vendorQuestionsRequest,
  vendorQuestionsResponse,
} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {
  getCurrentCompetitionsLocalAnswers,
  shouldWriteTempCompDoc,
} from '@chancer/common/lib/core/selectors/answers/AnswersSelectors';
import {getTemporaryUserScore} from '@chancer/common/lib/core/selectors/auth/AuthSelectors';
import {getDefaultDisabledContentBundle} from '@chancer/common/lib/core/selectors/bundle/BundleSelectors';
import {
  OLDEST_COMPETITION_DATE_WEB,
  getCompetition,
  getCompetitionId,
} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {getFilteredCompSummariesAndEntries} from '@chancer/common/lib/core/selectors/competitions/CompetitionSummarySelectors';
import {getRemoteChatConfig} from '@chancer/common/lib/core/selectors/remoteConfig/RemoteConfigSelectors';
import {
  getNeedsToRegisterForIsFollowing,
  getUser,
  getUserEntries,
  getUserId,
  getUserIsFollowing,
  getUserName,
  getUserPrivateProfile,
} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {getCompsForVendor} from '@chancer/common/lib/core/selectors/vendors/VendorSelectors';
import {IFirebaseTempEntryWithComp} from '@chancer/common/lib/core/state/AppState';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TFirebaseAnnouncement,
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompStatus,
  TFirebaseCompSummary,
  TFirebaseInboxMessage,
  TFirebaseLeaderboard,
  TFirebaseLeaders,
  TFirebaseQuestion,
  TFirebaseTempEntry,
  TFirebaseUser,
  TFirebaseUserFollowList,
  TFirebaseUserPrivateProfile,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  ChatMessageType,
  MediaLibraryItemStatus,
  QuestionStatus,
  TCompEntry,
  TInboxMessage,
  TJunctionCompetitionsQuestions,
  TMediaLibraryItemReady,
  TTempEntry,
  TUser,
  TUserFollowList,
  TUserPrivateProfile,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getCompetitionIsLive} from '@chancer/common/lib/utils/CompetitionUtils';
import {
  convert,
  convertClientDocument,
  convertToClientFirebaseChatMessage,
  convertToClientGroupByType,
  convertToClientLeadersByType,
  convertToMediaLibraryItemByType,
  getLargerAuthPhotoUrl,
  processSerialisedTimestamps,
} from '@chancer/common/lib/utils/FirebaseUtils';
import log from '@chancer/common/lib/utils/Log';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {getTemporaryUserScoreStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {createFallbackUserIcon} from '@chancer/common/lib/utils/UserUtils';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  Timestamp,
  addDoc,
  collection,
  collectionGroup,
  deleteField,
  doc,
  documentId,
  getDoc,
  getDocs,
  getDocsFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {trackFbPixel} from '../../analytics/FacebookPixel';
import {
  trackAppExecutionEvent,
  trackCustomEvent,
} from '../../analytics/FirebaseAnalytics';
import {getConfig} from '../../config/WebConfig';
import {firestore} from '../../firebase/Firebase';
import {getAttributionIdFromUrl} from '../../selectors/host/HostSelectors';
import {IWebAppState} from '../../state/WebAppState';

const db = () => firestore();

const createCollectionObserver = (
  q: CollectionReference<DocumentData> | Query<DocumentData>,
  forceRefresh: boolean = false,
) => {
  return of(q).pipe(
    switchMap(() =>
      concat(
        iif(
          () => forceRefresh,
          from(getDocsFromServer(q)).pipe(
            tap(() => log.debug('Forced server refresh')),
          ),
        ),
        fromEventPattern<QuerySnapshot>(
          (handler) => onSnapshot(q, handler),
          (_, unsubscribe) => unsubscribe(),
        ),
      ),
    ),
  );
};

const createDocumentObserver = (d: DocumentReference<DocumentData>) => {
  return fromEventPattern<DocumentSnapshot>(
    (handler) => onSnapshot(d, handler),
    (_, unsubscribe) => unsubscribe(),
  );
};

export const fetchCompetitionsEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    switchMap(() =>
      merge(
        concat(
          of(onCompetitionsRequest()),
          createCollectionObserver(
            query(
              collection(db(), 'competition_summaries'),
              where('starts', '>', OLDEST_COMPETITION_DATE_WEB),
            ),
          ).pipe(
            map((querySnap) =>
              querySnap.docs.map((d) =>
                convertClientDocument(TFirebaseCompSummary, d),
              ),
            ),
            map(sortComps),
            map(onCompetitionsResponse),
          ),
          of('').pipe(
            tap(() => log.debug('Competitions observer complete')),
            map(() => onCompetitionsError('Disconnected')),
          ),
        ).pipe(catchError((err) => of(onCompetitionsError(err)))),
        concat(
          of(announcementsRequest()),
          createCollectionObserver(
            query(
              collection(db(), 'announcements'),
              where('starts', '<', new Date()),
              where('ends', '>', new Date()),
              orderBy('created', 'desc'),
            ),
          ).pipe(
            map((querySnap) =>
              querySnap.docs.map((d) =>
                convertClientDocument(TFirebaseAnnouncement, d),
              ),
            ),
            map((comps) => announcementsResponse(comps)),
          ),
          of('').pipe(
            tap(() => log.debug('Announcement observer complete')),
            map(() => announcementsError('Disconnected')),
          ),
        ).pipe(catchError((err) => of(announcementsError(err)))),
      ),
    ),
  );

const sortComps = (comps: TFirebaseCompSummary[]) =>
  comps.sort((a, b) => b.starts.seconds - a.starts.seconds);

export const fetchCompetitionEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_COMPETITION),
    map((action) => action.payload as string | string[]),
    map((compParams) => validateCompetitionParameters(compParams)),
    filter(
      (compParams) =>
        !isCompetitionAlreadyLoaded(getCompetition(state$.value), compParams),
    ),
    switchMap((compParams) => getCompetitionStream(compParams)),
    catchError((err) => of(onCompetitionError(err.message))),
  );

const isCompetitionAlreadyLoaded = (
  comp: TFirebaseComp | null,
  params: string | string[],
) => {
  if (comp == null) {
    return false;
  }
  if (Array.isArray(params)) {
    if (params.length === 2) {
      return comp.vendor === params[0] && comp.key === params[1];
    }
  } else {
    return comp.id === params;
  }
  return false;
};

const getCompetitionStream = (compParams: string | string[]) =>
  concat(
    of(resetCompetition()),
    iif(
      () => isValidEmbeddedCompetition(compParams),
      of(processSerialisedTimestamps((window as any).competition)).pipe(
        tap(() => log.info('Using embedded competition')),
        map((competition) => Value.Cast(TFirebaseComp, competition)),
        map((competition) => onCompetitionResponse(competition)),
      ),
      concat(
        of(onCompetitionRequest(compParams)),
        createCompetitionObserver(compParams).pipe(
          map((competition) => onCompetitionResponse(competition)),
        ),
        of('').pipe(
          tap(() => log.debug('Competitions observer complete')),
          map(() => onCompetitionError('Disconnected')),
        ),
      ).pipe(catchError((err) => of(onCompetitionError(err)))),
    ),
  );

const isValidEmbeddedCompetition = (compParams: string | string[]) => {
  if ((window as any).competition !== undefined) {
    try {
      const comp = Value.Cast(TFirebaseComp, (window as any).competition);
      if (Array.isArray(compParams)) {
        return comp.vendor === compParams[0] && comp.key === compParams[1];
      } else {
        return comp.id === compParams;
      }
    } catch (err) {
      return false;
    }
  }
  return false;
};

const validateCompetitionParameters = (
  compParams: string | string[] | null,
) => {
  if (compParams === null) {
    throw new Error('No competition available');
  } else if (
    Array.isArray(compParams) &&
    (compParams.length !== 2 || !compParams[0] || !compParams[1])
  ) {
    log.warning('Invalid competition parameters', compParams);
    throw new Error('Invalid competition parameters');
  }

  return compParams;
};

const createCompetitionObserver = (compParams: string | string[]) => {
  if (Array.isArray(compParams)) {
    return createCollectionObserver(
      query(
        collection(db(), 'competitions'),
        where('vendor', '==', compParams[0]),
        where('key', '==', compParams[1]),
        where('published', '==', true),
        limit(1),
      ),
    ).pipe(
      map((querySnap) => {
        if (querySnap.empty) {
          throw Error('Supplied parameters do not match a competition');
        } else if (querySnap.size > 1) {
          throw Error('Supplied parameters match multiple competitions');
        }
        return convertClientDocument(TFirebaseComp, querySnap.docs[0]);
      }),
    );
  } else {
    return createDocumentObserver(doc(db(), 'competitions', compParams)).pipe(
      map((d) => convertClientDocument(TFirebaseComp, d)),
    );
  }
};

export const fetchCompetitionDetailsEpic: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.COMPETITION_RESPONSE),
    map(() => getCompetition(state$.value)),
    filter((comp): comp is TFirebaseComp => comp !== null),
    switchMap((comp) =>
      merge(
        getQuestionsStream(comp.id),
        getCompetitionStatusStream(`competitions/${comp.id}/comp_data/status`),
        getCompetitionCountsStream(`competitions/${comp.id}/comp_data/counts`),
        getCompetitionVipsStream(comp.vendor),
        getTemporaryUserScoreStream(getConfig().gameUrl)(
          getUserId(state$.value) ?? '',
          comp.id,
        ).pipe(map(onTemporaryUserScore)),
        action$.pipe(
          ofType(ActionType.COMPETITION_STATUS_RESPONSE),
          take(1),
          mergeMap(() =>
            getCompetitionChatStream(
              `competitions/${comp.id}/comp_chat`,
              getRemoteChatConfig(state$.value).maxMessages,
            ),
          ),
        ),
      ),
    ),
    catchError((err) => of(onCompetitionError(err.message))),
  );

const getQuestionsStream = (compId: string) =>
  concat(
    of(onQuestionsRequest(compId)),
    createCollectionObserver(
      query(
        collection(db(), 'junction_competitions_questions'),
        where('competitionId', '==', compId),
        where('status', '!=', QuestionStatus.DRAFT),
      ),
    ).pipe(
      map((querySnap) =>
        querySnap.docs
          .map((d) => convert(TJunctionCompetitionsQuestions, d))
          .sort((a, b) => a.questionIndex - b.questionIndex)
          .map((question) => question.questionId),
      ),
      switchMap((questionIds) =>
        getQuestionsFromIdsStream(questionIds).pipe(
          map(sortQuestions),
          map(onQuestionsResponse),
        ),
      ),
    ),
    of('').pipe(
      tap(() => log.debug('Questions observer complete')),
      map(() => onQuestionsError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(onQuestionsError(err))));

const getQuestionsFromIdsStream = (questionIds: string[]) =>
  questionIds.length === 0
    ? of([])
    : from(
        getDocs(
          query(
            collection(db(), 'questions'),
            where(documentId(), 'in', questionIds),
          ),
        ),
      ).pipe(
        map((querySnap) =>
          querySnap.docs
            .sort(
              (a, b) => questionIds.indexOf(a.id) - questionIds.indexOf(b.id),
            )
            .map((d, index) =>
              convertClientDocument(TFirebaseQuestion, d, {index}),
            ),
        ),
      );

const sortQuestions = (questions: TFirebaseQuestion[]) =>
  questions.sort((a, b) => a.index - b.index);

const getCompetitionCountsStream = (countsPath: string) =>
  concat(
    of(competitionCountsRequest(countsPath)),
    createDocumentObserver(doc(db(), countsPath)).pipe(
      map((d) => convertClientDocument(TFirebaseCompCounts, d)),
      map(competitionCountsResponse),
    ),
    of('').pipe(
      tap(() => log.debug('CompetitionCounts observer complete')),
      map(() => competitionCountsError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(competitionCountsError(err))));

const getCompetitionVipsStream = (vendorName: string) =>
  concat(
    of(competitionVipsRequest(vendorName)),
    from(
      getDocs(
        query(
          collection(db(), 'users'),
          where('vipVendors', 'array-contains', vendorName),
        ),
      ),
    ).pipe(
      map((querySnap) =>
        querySnap.docs.map((d) => convertClientDocument(TFirebaseUser, d)),
      ),
      map(competitionVipsResponse),
    ),
  ).pipe(catchError((err) => of(competitionVipsError(err))));

const getCompetitionStatusStream = (countsPath: string) =>
  concat(
    of(competitionStatusRequest(countsPath)),
    createDocumentObserver(doc(db(), countsPath)).pipe(
      map((d) => convertClientDocument(TFirebaseCompStatus, d)),
      map(competitionStatusResponse),
    ),
    of('').pipe(
      tap(() => log.debug('CompetitionStatus observer complete')),
      map(() => competitionStatusError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(competitionStatusError(err))));

const getCompetitionChatStream = (chatPath: string, maxMessages: number) =>
  concat(
    of(competitionChatRequest(chatPath)),
    createCollectionObserver(
      query(
        collection(db(), chatPath),
        orderBy('created', 'desc'),
        limit(maxMessages),
      ),
    ).pipe(
      map((querySnap) =>
        querySnap.docs
          .map(convertToClientFirebaseChatMessage)
          .filter((m) => m.type !== ChatMessageType.UNKNOWN),
      ),
      map((counts) => competitionChatResponse(counts)),
    ),
    of('').pipe(
      tap(() => log.debug('CompetitionChat observer complete')),
      map(() => competitionChatError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(competitionChatError(err))));

export const fetchLeaderboardConfigEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_LEADERBOARD_CONFIG),
    map((action) => action.payload),
    switchMap((leaderboardId) =>
      concat(
        of(leaderboardConfigRequest(leaderboardId)),
        createDocumentObserver(doc(db(), `leaderboards/${leaderboardId}`)).pipe(
          map((docSnap) =>
            convertClientDocument(TFirebaseLeaderboard, docSnap),
          ),
          map((config) => leaderboardConfigResponse(config)),
          catchError((err) => of(leaderboardConfigError(err))),
        ),
      ),
    ),
  );

export const fetchLeaderboardEpic: Epic<
  ReturnType<typeof fetchLeaderboard>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_LEADERBOARD),
    map((action) => action.payload),
    switchMap((payload) =>
      concat(
        of(leaderboardRequest(payload.leaderboardId)),
        createDocumentObserver(
          doc(
            db(),
            `leaderboards/${payload.leaderboardId}/leaderboard_data/leaders`,
          ),
        ).pipe(
          map((doc) => convertToClientLeadersByType(payload.isMultiGame, doc)),
          map(leaderboardResponse),
          catchError((err) => of(leaderboardError(err))),
        ),
      ),
    ),
  );

export const getFirestoreUserAfterAuth = async (
  authUser: User,
  comp: TFirebaseComp | null,
): Promise<{user: TFirebaseUser; isNew: boolean}> => {
  const userDoc = doc(db(), `users/${authUser.uid}`);
  const userSnap = await getDoc(userDoc);
  if (userSnap.exists()) {
    const existingUser = convertClientDocument(TFirebaseUser, userSnap);
    const setCreated = existingUser.created === undefined;
    if (
      (existingUser.temporary === true && !authUser.isAnonymous) || // upgraded!
      (existingUser.temporary === undefined && authUser.isAnonymous) // temporary, but was unset
    ) {
      await updateDoc(userDoc, {
        temporary: authUser.isAnonymous,
        updated: serverTimestamp() as any,
        ...(setCreated &&
          authUser.metadata.creationTime !== undefined && {
            created: Timestamp.fromDate(
              new Date(authUser.metadata.creationTime),
            ),
          }),
      });
      return {
        user: {
          ...existingUser,
          temporary: authUser.isAnonymous,
        },
        isNew: false,
      };
    } else if (setCreated && authUser.metadata.creationTime !== undefined) {
      await updateDoc(userDoc, {
        created: Timestamp.fromDate(new Date(authUser.metadata.creationTime)),
        updated: serverTimestamp() as any,
      });
    }
    return {
      user: existingUser,
      isNew: false,
    };
  } else {
    const profileDoc = doc(userDoc, 'user_profile', 'private');
    const newUser = assertType<TUser>({
      created: serverTimestamp() as any,
      name: '',
      media: authUser.photoURL
        ? {
            image: {
              url: getLargerAuthPhotoUrl(authUser.photoURL),
            },
          }
        : {},
      achievements: [],
      vendorLeaders: {},
      vendorAchievements: {},
      ...(authUser.isAnonymous && {
        temporary: true,
      }),
    });
    await Promise.all([
      setDoc(userDoc, {
        ...newUser,
        updated: serverTimestamp() as any,
      }),
      setDoc(
        profileDoc,
        assertType<TUserPrivateProfile>({
          updated: serverTimestamp() as any,
          subscriptions: {
            emailUpdates: true,
            emailAlerts: true,
            emailPromotions: true,
            sms: true,
          },
          fcmRegistered: false,
          fcmTokens: [],
          disabledLeagues: getDefaultDisabledContentBundle(comp),
        }),
      ),
    ]);
    return {
      user: {...newUser, id: authUser.uid, path: `users/${authUser.uid}`},
      isNew: true,
    };
  }
};

export const fetchUserEpic: Epic<IAction<any>, IAction<any>, IWebAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.FETCH_USER),
    map(() => getUserId(state$.value)),
    filter((id): id is string => id !== null),
    switchMap((id) =>
      merge(
        getUserStream(id),
        getUserPrivateStream(id),
        getUserFollowingStream(id),
        getUserEntriesStream(id),
        getInboxMessagesStream(id),
      ),
    ),
  );

const getUserStream = (userId: string) =>
  concat(
    of(userRequest(userId)),
    createDocumentObserver(doc(db(), `users/${userId}`)).pipe(
      map((d) => convertClientDocument(TFirebaseUser, d)),
      map((user) =>
        user.achievements === undefined ? {...user, achievements: []} : user,
      ),
      map((user) =>
        user.media?.image?.url
          ? user
          : {
              ...user,
              media: {image: {url: createFallbackUserIcon(user.id, user.name)}},
            },
      ),

      map((user) => userResponse(user)),
    ),
    of('').pipe(
      tap(() => log.debug('User observer complete')),
      map(() => userError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(userError(err))));

const getUserPrivateStream = (userId: string) =>
  concat(
    of(userPrivateRequest(userId)),
    createDocumentObserver(
      doc(db(), `users/${userId}/user_profile/private`),
    ).pipe(
      map((d) => convertClientDocument(TFirebaseUserPrivateProfile, d)),
      map((user) => ({...user, fcmTokens: user.fcmTokens || []})), //Accommodate for fcmTokens being undefined
      map(userPrivateResponse),
    ),
    of('').pipe(
      tap(() => log.debug('User observer complete')),
      map(() => userPrivateError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(userPrivateError(err))));

const getUserFollowingStream = (userId: string) =>
  concat(
    of(userFollowingRequest(userId)),
    createDocumentObserver(
      doc(db(), `users/${userId}/user_profile/following`),
    ).pipe(
      map((d) => convertClientDocument(TFirebaseUserFollowList, d)),
      map(userFollowingResponse),
    ),
    of('').pipe(
      tap(() => log.debug('User observer complete')),
      map(() => userError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(userFollowingError(err))));

const getInboxMessagesStream = (userId: string) =>
  concat(
    of(inboxMessagesRequest(userId)),
    createCollectionObserver(
      query(
        collection(db(), 'messages'),
        where('userId', '==', userId),
        limit(9),
      ),
    ).pipe(
      map((querySnap) =>
        querySnap.docs.map((d) =>
          convertClientDocument(TFirebaseInboxMessage, d),
        ),
      ),
      map((messages) =>
        messages.sort((a, b) => b.created.seconds - a.created.seconds),
      ),
      map(inboxMessagesResponse),
    ),
    of('').pipe(
      tap(() => log.debug('User observer complete')),
      map(() => inboxMessagesError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(inboxMessagesError(err))));

const getUserEntriesStream = (userId: string) =>
  concat(
    of(userEntriesRequest(userId)),
    createCollectionObserver(
      query(
        collectionGroup(db(), 'comp_entries'),
        where('userId', '==', userId),
        where('updated', '>', OLDEST_COMPETITION_DATE_WEB),
      ),
    ).pipe(
      switchMap((querySnap) =>
        of('').pipe(
          map(() =>
            querySnap.docs.map((d) =>
              convertClientDocument(TFirebaseCompEntry, d),
            ),
          ),
          switchMap((entries) =>
            concat(
              of(userEntriesResponse(entries)),
              of(cleanUpLocalAnswers(entries)),
            ),
          ),
        ),
      ),
    ),
    of('').pipe(
      tap(() => log.debug('User observer complete')),
      map(() => userEntriesError('Disconnected')),
    ),
  ).pipe(catchError((err) => of(userEntriesError(err))));

export const fetchInboxMessageOpenEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_INBOX_MESSAGE_OPEN),
    switchMap((action) =>
      from(
        updateDoc(
          doc(db(), 'messages', action.payload),
          assertType<Pick<TInboxMessage, 'open' | 'opened'>>({
            open: true,
            opened: serverTimestamp() as any,
          }),
        ),
      ).pipe(map(() => noOpAction())),
    ),
  );

export const fetchDeleteUserEpic: Epic<
  IAction<Partial<TUser>>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_DELETE_USER),
    map(() => getUserId(state$.value)),
    filter((userId): userId is string => userId !== null),
    switchMap((userId) =>
      concat(
        from(updateUserStream(userId, {instruction: 'DELETE'})).pipe(
          map(() => updateUserResponse()),
          catchError((err) => of(updateUserError(err))),
        ),
        of(logOut()),
      ),
    ),
  );

export const fetchUpdateUserEpic: Epic<
  IAction<Partial<TUser>>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_UPDATE_USER),
    switchMap((action) =>
      of(action).pipe(
        map(() => action.payload),
        switchMap((user) =>
          concat(
            of(updateUserRequest(user)),
            from(
              updateUserStream(getUserId(state$.value) as string, user),
            ).pipe(
              map(() => updateUserResponse()),
              catchError((err) => of(updateUserError(err))),
            ),
            // If we do not have a stream registered for the user, do it
            of(getUser(state$.value)).pipe(
              filter((existingUser) => existingUser === null),
              map(() => fetchUser()),
            ),
          ),
        ),
      ),
    ),
  );

const updateUserStream = (userId: string, user: Partial<TUser>) => {
  let v: keyof TUser;
  for (v in user) {
    // If field is explicitly set to undefined in update object, swap it out with a delete instruction
    if (user[v] === undefined) {
      user[v] = deleteField() as any;
    }
  }
  return updateDoc(doc(db(), 'users', userId), {
    ...user,
    updated: serverTimestamp() as any,
  });
};

export const fetchUpdateUserPrivateEpic: Epic<
  IAction<Partial<TUserPrivateProfile>>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_UPDATE_USER_PRIVATE),
    map((action) => action.payload),
    switchMap((userPrivate) =>
      concat(
        of(updateUserPrivateRequest(userPrivate)),
        from(
          updateUserPrivateStream(
            getUserId(state$.value) as string,
            userPrivate,
          ),
        ).pipe(
          map(() => updateUserPrivateResponse()),
          catchError((err) => of(updateUserPrivateError(err))),
        ),
      ),
    ),
  );

const updateUserPrivateStream = (
  userId: string,
  profile: Partial<TUserPrivateProfile>,
) => {
  return updateDoc(doc(db(), 'users', userId, 'user_profile', 'private'), {
    ...profile,
    updated: serverTimestamp() as any,
  });
};

export const enterCompetitionEpic: Epic<
  IAction<IEnterCompPayload>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_ENTER_COMPETITION),
    tap(() =>
      addBreadcrumb({
        category: 'entry',
        message: 'Enter comp executed',
      }),
    ),
    tap((action) =>
      trackAppExecutionEvent('competition_entry_executed', {
        competitionId: action.payload.competitionId,
      }),
    ),
    map((action) => {
      return {
        batch: createBatchedCompetitionEntry(state$.value, action.payload),
        payload: assertType<IEnterCompPayload>({
          ...action.payload,
          competitionId:
            action.payload.competitionId ?? getCompetitionId(state$.value), // Just ensuring we have a comp id as clients update
        }),
      };
    }),
    switchMap(({batch, payload}) =>
      concat(
        of(enterCompetitionRequest(payload.competitionId)),
        from(batch.commit()).pipe(
          tap(() =>
            payload.isEntry
              ? trackCustomEvent('competition_entry', {
                  competitionId: payload.competitionId,
                  attributionId: getAttributionIdFromUrl(state$.value),
                })
              : trackCustomEvent('competition_entry_new_questions', {
                  competitionId: payload.competitionId,
                  attributionId: getAttributionIdFromUrl(state$.value),
                }),
          ),
          tap(
            () =>
              payload.isEntry &&
              trackFbPixel('trackCustom', 'competition_entry', {
                competitionId: payload.competitionId,
              }),
          ),
          map(() => enterCompetitionResponse(payload.competitionId)),
          catchError((err) =>
            of('').pipe(
              tap(() =>
                trackAppExecutionEvent('competition_entry_failed', {
                  competitionId: payload.competitionId,
                }),
              ),
              tap(() =>
                addBreadcrumb({
                  category: 'entry',
                  message: 'Enter comp failure',
                }),
              ),
              tap(() => captureException(err)),
              map(() => enterCompetitionError(err)),
            ),
          ),
        ),
        // If we do not have a stream registered for the user, do it
        of(getUser(state$.value)).pipe(
          filter((existingUser) => existingUser === null),
          map(() => fetchUser()),
        ),
        // If required, subscribe the user to the league for the game they have just entered
        of([
          getUserPrivateProfile(state$.value),
          getCompetition(state$.value),
        ]).pipe(
          filter(() => payload.isEntry),
          filter(
            (
              profileAndComp,
            ): profileAndComp is [TFirebaseUserPrivateProfile, TFirebaseComp] =>
              profileAndComp[0] !== null && profileAndComp[1] !== null,
          ),
          filter(
            ([profile, comp]) =>
              profile.disabledLeagues?.includes(comp.league) ?? false,
          ),
          map(([profile, comp]) =>
            fetchUpdateUserPrivate({
              disabledLeagues: (profile.disabledLeagues ?? []).filter(
                (l) => l !== comp.league,
              ),
            }),
          ),
        ),
      ),
    ),
  );

const createBatchedCompetitionEntry = (
  state: IWebAppState,
  usersEntry: IEnterCompPayload,
) => {
  const userId = getUserId(state) as string;
  const user = getUser(state);
  const compId = usersEntry.competitionId;
  const usersEntries = getUserEntries(state);
  const existingEntry = usersEntries.get(compId);
  const localAnswers = usersEntry.answers;
  const temporaryUserScore = getTemporaryUserScore(state);

  const batch = writeBatch(db());
  const userRef = doc(db(), 'users', userId);
  const userPrivateRef = doc(userRef, 'user_profile', 'private');

  if (
    usersEntry.name !== undefined &&
    getUserName(state) !== usersEntry.name &&
    !(getUserName(state) !== null && usersEntry.name === 'UNSET') // Don't overwrite the name with UNSET)
  ) {
    batch.update(
      userRef,
      assertType<Pick<TUser, 'name' | 'updated'>>({
        name: usersEntry.name,
        updated: serverTimestamp() as any,
      }),
    );
  }

  if (user?.temporary === true && usersEntry.isEntry) {
    batch.update(
      userPrivateRef,
      assertType<Pick<TUserPrivateProfile, 'temporaryUserScore' | 'updated'>>({
        temporaryUserScore,
        updated: serverTimestamp() as any,
      }),
    );
  }

  const compRef = doc(db(), 'competitions', compId);

  const answersRef = doc(compRef, 'comp_entries', userId);
  const entry: TCompEntry = {
    updated: serverTimestamp() as any,
    userId,
    compId,
    answers: {...(existingEntry?.answers ?? {}), ...localAnswers.answers},
    likes: {...(existingEntry?.likes ?? {}), ...(localAnswers.likes ?? {})},
  };
  if (!existingEntry) {
    entry.created = serverTimestamp() as any;
  }

  const att = getAttributionIdFromUrl(state);
  if (att !== null) {
    entry.attId = att;
  }
  batch.set(answersRef, entry);

  if (
    localAnswers.tempRemoteId !== null &&
    localAnswers.tempRemoteId !== undefined
  ) {
    batch.delete(doc(compRef, 'temp_entries', localAnswers.tempRemoteId));
  }
  return batch;
};

export const saveEntryDetailsToTempDoc: Epic<
  IAction<any>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.QUESTION_ANSWERED),
    filter(() => shouldWriteTempCompDoc(state$.value)),
    map(() => getCurrentCompetitionsLocalAnswers(state$.value)),
    map((localAnswers) =>
      assertType<TTempEntry>({answers: localAnswers.answers}),
    ),
    switchMap((localAnswers) =>
      concat(
        of(tempEntryRequest(localAnswers)),
        from(createTempEntry(state$.value, localAnswers)).pipe(
          map(([compId, docId]) => tempEntryResponse(compId, docId)),
          catchError((err) => of(tempEntryError(err))),
        ),
      ),
    ),
  );

const createTempEntry = async (state: IWebAppState, answers: TTempEntry) => {
  const compId = getCompetitionId(state) as string;
  const compRef = doc(db(), 'competitions', compId);
  const tempCollectionRef = collection(compRef, 'temp_entries');
  const tempDoc = await addDoc(tempCollectionRef, answers);
  return [compId, tempDoc.id];
};

export const fetchGetTempEntryEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_GET_TEMP_ENTRY),
    map((action) => action.payload),
    switchMap((id) =>
      concat(
        of(getTempEntryRequest(id)),
        from(
          createTempEntryObserver(getCompetitionId(state$.value) as string, id),
        ).pipe(
          map((d) => convertClientDocument(TFirebaseTempEntry, d)),
          map(
            (tempEntry) =>
              ({
                ...tempEntry,
                competitionId: getCompetitionId(state$.value) as string,
              }) as IFirebaseTempEntryWithComp,
          ),
          tap(() => trackCustomEvent('restored_using_temp_id')),
          map(getTempEntryResponse),
          catchError((err) => of(getTempEntryError(err))),
        ),
      ),
    ),
  );

const createTempEntryObserver = (compId: string, tempId: string) => {
  return getDoc(doc(db(), 'competitions', compId, 'temp_entries', tempId));
};

export const fetchUserFollowingEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_USER_FOLLOWING),
    mergeMap(() => getUserFollowingStream(getUserId(state$.value) as string)),
  );

export const fetchFollowUserEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_FOLLOW_USER),
    map((action) => action.payload),
    map((idToFollow) => ({
      needsToRegister: getNeedsToRegisterForIsFollowing(state$.value),
      followList: addUserToFollowList(
        getUserIsFollowing(state$.value),
        idToFollow,
      ),
    })),
    switchMap((info) =>
      concat(
        // Set result straight away if we are not registered to snapshot
        of(info).pipe(
          filter(() => info.needsToRegister),
          map(() =>
            userFollowingResponse({
              ...info.followList,
              id: 'temp',
              path: 'temp',
            }),
          ),
        ),
        // Update value in db
        updateFollowListStream(
          info.followList,
          getUserId(state$.value) as string,
        ).pipe(
          map(() => noOpAction()),
          catchError(() => of(noOpAction())),
        ),
        // Register if we need to
        of(info).pipe(
          filter(() => info.needsToRegister),
          map(() => fetchUserFollowing()),
        ),
      ),
    ),
  );

export const fetchUnfollowUserEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_UNFOLLOW_USER),
    map((action) => action.payload),
    map((idToUnfollow) =>
      removeUserFromFollowList(getUserIsFollowing(state$.value), idToUnfollow),
    ),
    switchMap((followList) =>
      updateFollowListStream(
        followList,
        getUserId(state$.value) as string,
      ).pipe(
        map(() => noOpAction()),
        catchError(() => of(noOpAction())),
      ),
    ),
  );

const updateFollowListStream = (followList: TUserFollowList, userId: string) =>
  from(setDoc(doc(db(), `users/${userId}/user_profile/following`), followList));

const addUserToFollowList = (
  list: TFirebaseUserFollowList,
  userId: string,
): TUserFollowList => {
  if (list.userIds.find((id) => id === userId) === undefined) {
    return {
      userIds: [...list.userIds, userId],
    };
  }
  return {
    userIds: list.userIds,
  };
};

const removeUserFromFollowList = (
  list: TFirebaseUserFollowList,
  userId: string,
): TUserFollowList => {
  return {
    userIds: list.userIds.filter((id) => id !== userId),
  };
};

export const fetchGroupEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_GROUP),
    map((action) => action.payload),
    switchMap((groupId) =>
      concat(
        of(groupRequest(groupId)),
        from(getDoc(doc(db(), `groups/${groupId}`))).pipe(
          map((d) => convertToClientGroupByType(d)),
          map(groupResponse),
          catchError((err) => of(groupError(err))),
        ),
      ),
    ),
  );

const getCompsForVendorDetails = (
  comps: ICompSummaryAndEntry[],
  vendor: string,
  count: number,
) =>
  getCompsForVendor(comps, vendor)
    .slice(0, count)
    .map((c) => c.summary);

export const fetchVendorCompDetailsEpic: Epic<
  IAction<string>,
  IAction<any>,
  IWebAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.FETCH_VENDOR_COMP_DETAILS),
    switchMap((action) =>
      // Need to invalidate the other streams if the competitions change
      merge(
        of(''), // <-- Triggers the first run
        action$.pipe(ofType(ActionType.COMPETITIONS_RESPONSE)),
      )
        .pipe(
          switchMap(() =>
            merge(
              getCompsCountsStream(
                getCompsForVendorDetails(
                  getFilteredCompSummariesAndEntries(state$.value),
                  action.payload,
                  2,
                ),
                vendorCompCountsRequest,
                vendorCompCountsResponse,
                vendorCompCountsError,
              ),
              getCompsLiveLeaderboardsStream(
                getCompsForVendorDetails(
                  getFilteredCompSummariesAndEntries(state$.value),
                  action.payload,
                  2,
                ),
                vendorLiveLeaderboardsRequest,
                vendorLiveLeaderboardsResponse,
                vendorLiveLeaderboardsError,
              ),
              getCompsQuestionsStream(
                getCompsForVendorDetails(
                  getFilteredCompSummariesAndEntries(state$.value),
                  action.payload,
                  2,
                ),
                vendorQuestionsRequest,
                vendorQuestionsResponse,
                vendorQuestionsError,
              ),
              getCompNewsStream(
                getCompsForVendorDetails(
                  getFilteredCompSummariesAndEntries(state$.value),
                  action.payload,
                  1,
                )?.[0],
                vendorNewsRequest,
                vendorNewsResponse,
                vendorNewsError,
              ),
            ),
          ),
        )
        .pipe(
          // Cancel the streams when we get a close action
          takeUntil(action$.pipe(ofType(ActionType.FETCH_CLOSE_VENDOR))),
        ),
    ),
  );

const getCompsCountsStream = (
  competitions: TFirebaseCompSummary[],
  requestAction: (compIds: string[]) => IAction<string[]>,
  responseAction: (comps: {[key: string]: TFirebaseCompCounts}) => IAction<{
    [key: string]: TFirebaseCompCounts;
  }>,
  errorAction: (err: any) => IAction<string>,
) =>
  of(competitions).pipe(
    mergeMap((comps) =>
      concat(
        of(requestAction(comps.map((c) => c.id))),
        combineLatest(
          comps.map((comp) =>
            of(comp).pipe(
              mergeMap(() =>
                iif(
                  () => getCompetitionIsLive(comp.status),
                  createDocumentObserver(
                    doc(db(), `competitions/${comp.id}/comp_data/counts`),
                  ),
                  from(
                    getDoc(
                      doc(db(), `competitions/${comp.id}/comp_data/counts`),
                    ),
                  ),
                ).pipe(
                  map((doc) => ({
                    [comp.id]: convertClientDocument(TFirebaseCompCounts, doc),
                  })),
                  catchError((err) =>
                    of(err).pipe(
                      tap(() => log.debug('getCompsCountsStream error', err)),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ).pipe(
          map((counts) =>
            counts.reduce(
              (acc: {[key: string]: TFirebaseCompCounts}, count) => {
                return {...acc, ...count};
              },
              {},
            ),
          ),
          map(responseAction),
        ),
      ).pipe(catchError((err) => of(errorAction(err)))),
    ),
  );

const getCompsLiveLeaderboardsStream = (
  competitions: TFirebaseCompSummary[],
  requestAction: (compIds: string[]) => IAction<string[]>,
  responseAction: (comps: {[key: string]: TFirebaseLeaders}) => IAction<{
    [key: string]: TFirebaseLeaders;
  }>,
  errorAction: (err: any) => IAction<string>,
) =>
  of(competitions).pipe(
    map((comps) => comps.filter((c) => getCompetitionIsLive(c.status))),
    mergeMap((comps) =>
      concat(
        of(requestAction(comps.map((c) => c.id))),
        combineLatest(
          comps.map((comp) =>
            of(comp).pipe(
              mergeMap(() =>
                createDocumentObserver(
                  doc(db(), `leaderboards/${comp.id}/leaderboard_data/leaders`),
                ).pipe(
                  map((doc) => ({
                    [comp.id]: convertClientDocument(TFirebaseLeaders, doc),
                  })),
                  catchError((err) =>
                    of(err).pipe(
                      tap(() => log.debug('Groups Leaderboard error', err)),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ).pipe(
          map((counts) =>
            counts.reduce((acc: {[key: string]: TFirebaseLeaders}, count) => {
              return {...acc, ...count};
            }, {}),
          ),
          map(responseAction),
        ),
      ).pipe(catchError((err) => of(errorAction(err)))),
    ),
  );

const getCompsQuestionsStream = (
  competitions: TFirebaseCompSummary[],
  requestAction: (compIds: string[]) => IAction<string[]>,
  responseAction: (comps: {[key: string]: TFirebaseQuestion[]}) => IAction<{
    [key: string]: TFirebaseQuestion[];
  }>,
  errorAction: (err: any) => IAction<string>,
) =>
  of(competitions.map((c) => c.id)).pipe(
    mergeMap((compIds) =>
      concat(
        of(requestAction(compIds)),
        combineLatest(
          compIds.map((compId) =>
            of(compId).pipe(
              mergeMap(() =>
                from(
                  getDocs(
                    query(
                      collection(db(), 'junction_competitions_questions'),
                      where('competitionId', '==', compId),
                      where('status', '!=', QuestionStatus.DRAFT),
                    ),
                  ),
                ).pipe(
                  map((querySnap) =>
                    querySnap.docs
                      .map((d) => convert(TJunctionCompetitionsQuestions, d))
                      .sort((a, b) => a.questionIndex - b.questionIndex)
                      .map((question) => question.questionId),
                  ),
                  switchMap((questionIds) =>
                    getQuestionsFromIdsStream(questionIds).pipe(
                      map((questions) => ({[compId]: questions})),
                    ),
                  ),
                  catchError((err) =>
                    of(err).pipe(
                      tap(() => log.debug('Groups Questions error', err)),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ).pipe(
          map((questions) =>
            questions.reduce(
              (acc: {[key: string]: TFirebaseQuestion[]}, question) => {
                return {...acc, ...question};
              },
              {},
            ),
          ),
          map(responseAction),
        ),
      ).pipe(catchError((err) => of(errorAction(err)))),
    ),
  );

export const getCompNewsStream = (
  comp: TFirebaseCompSummary | undefined,
  requestAction: (tags: string[]) => IAction<string[]>,
  responseAction: (
    news: TMediaLibraryItemReady[],
  ) => IAction<TMediaLibraryItemReady[]>,
  errorAction: (err: any) => IAction<string>,
) =>
  of(comp).pipe(
    filter((c): c is TFirebaseCompSummary => c !== undefined),
    map((c) => c.tags ?? []),
    mergeMap((tags) =>
      concat(
        of(requestAction(tags ?? [])),
        createCollectionObserver(
          tags.length === 0
            ? query(
                collection(db(), 'media_library'),
                orderBy('created', 'desc'),
                limit(10),
              )
            : query(
                collection(db(), 'media_library'),
                where('tags', 'array-contains-any', tags),
                orderBy('created', 'desc'),
                limit(10),
              ),
        ).pipe(
          map((querySnap) =>
            querySnap.docs
              .map(convertToMediaLibraryItemByType)
              .filter((m) => m.status === MediaLibraryItemStatus.READY),
          ),
          map(responseAction),
        ),
      ).pipe(catchError((err) => of(errorAction(err)))),
    ),
  );
