interface ISportAndLeague {
  name: string;
  leagues: ILeagueAndBundle[];
}
interface ILeagueAndBundle {
  name: string;
  bundles: BUNDLES[];
}

export enum BUNDLES {
  USA = 'USA',
  AUSTRALASIA = 'AUSTRALASIA',
}

export const EXCLUDED_GROUPS_VENDORS = ['nznbl'];

export const VENDORS = [
  {id: 'warriors', label: 'Warriors'},
  {id: 'crusaders', label: 'Crusaders'},
  {id: 'hurricanes', label: 'Hurricanes'},
  {id: 'blackcaps', label: 'Blackcaps'},
  {id: 'rabbitohs', label: 'Rabbitohs'},
  {id: 'dodgers', label: 'Dodgers'},
  {id: 'superrugby', label: 'Super Rugby'},
  {id: 'stateoforigin', label: 'State Of Origin'},
  {id: '49ers', label: '49ers'},
  {id: 'bteam', label: 'UCLA BTeam'},
  {id: 'samsncaa', label: 'Sams NCAA'},
  {id: 'calbears', label: 'California Bears'},
  {id: 'chancernfl', label: 'Chancer NFL'},
  {id: 'jkbogen', label: 'Jake Ellenbogen'},
  {id: 'maxg', label: 'Max G'},
  {id: 't20worldcup', label: 'T20 World Cup'},
  {id: 'crowdgoeswild', label: 'Crowd Goes Wild'},
  {id: 'nblxnba', label: 'NBL x NBA'},
  {id: '1news', label: '1News'},
  {id: 'awards', label: 'Awards'},
  {id: 'mediaawards', label: 'Media Awards'},
  {id: 'fifawwc', label: 'FIFA WWC'},
  {id: 'rwc23', label: 'RWC23'},
  {id: 'nzsport', label: 'NZ SPORT'},
  {id: 'nzmotors', label: 'NZ MOTORS'},
  {id: 'nzwomen', label: 'NZ WOMEN'},
  {id: 'nznbl', label: 'NZ NBL'},
  {id: 'nznblrapid', label: 'NZ NBL Rapid League'},
  {id: 'nblsaints', label: 'Wellington Saints'},
  {id: 'nblrams', label: 'Canterbury Rams'},
  {id: 'nbltuatara', label: 'Auckland Tuatara'},
  {id: 'hoopheads', label: 'Hoop Heads'},
  {id: 'womenzsports', label: 'Womenz Sports'},
  {id: 'nzrugby', label: 'NZ Rugby'},
  {id: 'nbl', label: 'Australian NBL'},
  {id: 'breakers', label: 'NZ Breakers'},
];

export const VENDOR_IDS = VENDORS.map((v) => v.id);

export const SPORTS_DATA: ISportAndLeague[] = [
  {name: 'Baseball', leagues: [{name: 'MLB', bundles: [BUNDLES.USA]}]},
  {
    name: 'American Football',
    leagues: [
      {name: 'NFL', bundles: [BUNDLES.USA]},
      {name: 'NCAA', bundles: [BUNDLES.USA]},
    ],
  },
  {
    name: 'Basketball',
    leagues: [
      {name: 'NBA', bundles: [BUNDLES.USA]},
      {name: 'NBL', bundles: [BUNDLES.AUSTRALASIA]},
    ],
  },
  {
    name: 'Rugby League',
    leagues: [{name: 'NRL', bundles: [BUNDLES.AUSTRALASIA]}],
  },
  {
    name: 'Rugby Union',
    leagues: [
      {name: 'RWC', bundles: [BUNDLES.USA, BUNDLES.AUSTRALASIA]},
      {name: 'Mitre 10', bundles: [BUNDLES.AUSTRALASIA]},
      {name: 'Super Rugby', bundles: [BUNDLES.AUSTRALASIA]},
      {name: 'Black Ferns', bundles: [BUNDLES.AUSTRALASIA]},
      {name: 'All', bundles: [BUNDLES.USA, BUNDLES.AUSTRALASIA]},
    ],
  },
  {
    name: 'Football',
    leagues: [
      {name: 'FIFA', bundles: [BUNDLES.USA, BUNDLES.AUSTRALASIA]},
      // {name: 'EPL', bundles: [BUNDLES.USA, BUNDLES.AUSTRALASIA]},
    ],
  },
  {
    name: 'Motorsport',
    leagues: [{name: 'Supercars', bundles: [BUNDLES.AUSTRALASIA]}],
  },
  {
    name: 'Cricket',
    leagues: [
      {name: 'T20 Internationals', bundles: [BUNDLES.AUSTRALASIA]},
      {name: 'Test Cricket', bundles: [BUNDLES.AUSTRALASIA]},
    ],
  },
  {
    name: 'Media',
    leagues: [
      {name: 'Sky TV', bundles: [BUNDLES.AUSTRALASIA]},
      {name: '1News', bundles: [BUNDLES.AUSTRALASIA]},
      {name: 'Awards', bundles: [BUNDLES.AUSTRALASIA]},
    ],
  },
];

export const SPORTS = SPORTS_DATA.map((s) => s.name);

export const LEAGUES = SPORTS_DATA.reduce(
  (leagues: string[], sportAndLeague: ISportAndLeague) =>
    leagues.concat(sportAndLeague.leagues.map((l) => l.name)),
  [],
);

export const LEAGUES_BY_SPORT = SPORTS_DATA.map((s) => ({
  ...s,
  leagues: s.leagues.map((l) => l.name),
}));

type LeagueByBundle = Record<BUNDLES, string[]>;

export const LEAGUES_BY_BUNDLE = SPORTS_DATA.reduce(
  (leagues: Partial<LeagueByBundle>, sportAndLeague: ISportAndLeague) => {
    sportAndLeague.leagues.forEach((l) => {
      l.bundles.forEach((b) => {
        if (!leagues[b]) {
          leagues[b] = [];
        }
        leagues[b]?.push(l.name);
      });
    });
    return leagues;
  },
  {},
) as LeagueByBundle;
