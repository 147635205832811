import {Reducer} from 'redux';
import {ActionType, IAction} from '../../actions/Actions';
import {IFirestoreServerApi} from '../../state/AppState';
import {
  handleServerResponse,
  firestoreServerApiHandler,
  undefinedInReducerError,
} from '../ReducerUtils';

export const firestoreReducer: Reducer<IFirestoreServerApi, IAction<any>> = (
  state: IFirestoreServerApi | undefined,
  action: IAction<any>,
): IFirestoreServerApi => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.RESET_INITIAL_VIEW_STATE:
      return {
        ...state,
        competition: {...state.competition, error: null}, // Clear the error if we reset the state which should only happen when leaving a comp
      };
    case ActionType.RESET_COMPETITION:
      return {
        ...state,
        competition: handleServerResponse(state.competition, action),
        questions: handleServerResponse(state.questions, action),
        competitionStatus: handleServerResponse(
          state.competitionStatus,
          action,
        ),
        competitionChat: handleServerResponse(state.competitionChat, action),
        competitionCounts: handleServerResponse(
          state.competitionCounts,
          action,
        ),
        competitionVips: handleServerResponse(state.competitionVips, action),
        leaders: handleServerResponse(state.leaders, action),
        leaderboardConfiguration: handleServerResponse(
          state.leaderboardConfiguration,
          action,
        ),
      };
    case ActionType.ADD_GROUP_RESET:
      return {
        ...state,
        addGroup: handleServerResponse(state.userGroups, action),
      };
    case ActionType.AUTH_LOGGED_OUT:
      return {
        ...state,
        enterCompetition: handleServerResponse(state.enterCompetition, action),
        user: handleServerResponse(state.user, action),
        userEntries: handleServerResponse(state.userEntries, action),
        userPrivate: handleServerResponse(state.userPrivate, action),
        userMessages: handleServerResponse(state.userMessages, action),
        userGroups: handleServerResponse(state.userGroups, action),
        userFollowing: handleServerResponse(state.userFollowing, action),
      };
  }

  const firestoreActionHandler = HANDLERS[action.type];
  if (firestoreActionHandler) {
    return firestoreActionHandler(state, action);
  }
  return state;
};

const HANDLERS = {
  ...firestoreServerApiHandler(
    'competitions',
    ActionType.COMPETITIONS_REQUEST,
    ActionType.COMPETITIONS_RESPONSE,
    ActionType.COMPETITIONS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'competition',
    ActionType.COMPETITION_REQUEST,
    ActionType.COMPETITION_RESPONSE,
    ActionType.COMPETITION_ERROR,
  ),
  ...firestoreServerApiHandler(
    'questions',
    ActionType.QUESTIONS_REQUEST,
    ActionType.QUESTIONS_RESPONSE,
    ActionType.QUESTIONS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'user',
    ActionType.USER_REQUEST,
    ActionType.USER_RESPONSE,
    ActionType.USER_ERROR,
  ),
  ...firestoreServerApiHandler(
    'updateUser',
    ActionType.UPDATE_USER_REQUEST,
    ActionType.UPDATE_USER_RESPONSE,
    ActionType.UPDATE_USER_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userPrivate',
    ActionType.USER_PRIVATE_REQUEST,
    ActionType.USER_PRIVATE_RESPONSE,
    ActionType.USER_PRIVATE_ERROR,
  ),
  ...firestoreServerApiHandler(
    'updateUserPrivate',
    ActionType.UPDATE_USER_PRIVATE_REQUEST,
    ActionType.UPDATE_USER_PRIVATE_RESPONSE,
    ActionType.UPDATE_USER_PRIVATE_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userFollowing',
    ActionType.USER_FOLLOWING_REQUEST,
    ActionType.USER_FOLLOWING_RESPONSE,
    ActionType.USER_FOLLOWING_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userEntries',
    ActionType.USER_ENTRIES_REQUEST,
    ActionType.USER_ENTRIES_RESPONSE,
    ActionType.USER_ENTRIES_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userEntries',
    ActionType.USER_ENTRIES_REQUEST,
    ActionType.USER_ENTRIES_RESPONSE,
    ActionType.USER_ENTRIES_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userGroups',
    ActionType.USER_GROUPS_REQUEST,
    ActionType.USER_GROUPS_RESPONSE,
    ActionType.USER_GROUPS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'userMessages',
    ActionType.INBOX_MESSAGES_REQUEST,
    ActionType.INBOX_MESSAGES_RESPONSE,
    ActionType.INBOX_MESSAGES_ERROR,
  ),
  ...firestoreServerApiHandler(
    'enterCompetition',
    ActionType.ENTER_COMPETITION_REQUEST,
    ActionType.ENTER_COMPETITION_RESPONSE,
    ActionType.ENTER_COMPETITION_ERROR,
  ),
  ...firestoreServerApiHandler(
    'leaderboardConfiguration',
    ActionType.LEADERBOARD_CONFIG_REQUEST,
    ActionType.LEADERBOARD_CONFIG_RESPONSE,
    ActionType.LEADERBOARD_CONFIG_ERROR,
  ),
  ...firestoreServerApiHandler(
    'leaders',
    ActionType.LEADERBOARD_REQUEST,
    ActionType.LEADERBOARD_RESPONSE,
    ActionType.LEADERBOARD_ERROR,
  ),
  ...firestoreServerApiHandler(
    'competitionCounts',
    ActionType.COMPETITION_COUNTS_REQUEST,
    ActionType.COMPETITION_COUNTS_RESPONSE,
    ActionType.COMPETITION_COUNTS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'competitionVips',
    ActionType.COMPETITION_VIPS_REQUEST,
    ActionType.COMPETITION_VIPS_RESPONSE,
    ActionType.COMPETITION_VIPS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'competitionStatus',
    ActionType.COMPETITION_STATUS_REQUEST,
    ActionType.COMPETITION_STATUS_RESPONSE,
    ActionType.COMPETITION_STATUS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'competitionChat',
    ActionType.COMPETITION_CHAT_REQUEST,
    ActionType.COMPETITION_CHAT_RESPONSE,
    ActionType.COMPETITION_CHAT_ERROR,
  ),
  ...firestoreServerApiHandler(
    'sendChat',
    ActionType.SEND_CHAT_REQUEST,
    ActionType.SEND_CHAT_RESPONSE,
    ActionType.SEND_CHAT_ERROR,
  ),
  ...firestoreServerApiHandler(
    'tempEntry',
    ActionType.TEMP_ENTRY_REQUEST,
    ActionType.TEMP_ENTRY_RESPONSE,
    ActionType.TEMP_ENTRY_ERROR,
  ),
  ...firestoreServerApiHandler(
    'getTempEntry',
    ActionType.GET_TEMP_ENTRY_REQUEST,
    ActionType.GET_TEMP_ENTRY_RESPONSE,
    ActionType.GET_TEMP_ENTRY_ERROR,
  ),
  ...firestoreServerApiHandler(
    'group',
    ActionType.GROUP_REQUEST,
    ActionType.GROUP_RESPONSE,
    ActionType.GROUP_ERROR,
  ),
  ...firestoreServerApiHandler(
    'addGroup',
    ActionType.ADD_GROUP_REQUEST,
    ActionType.ADD_GROUP_RESPONSE,
    ActionType.ADD_GROUP_ERROR,
  ),
  ...firestoreServerApiHandler(
    'editGroup',
    ActionType.EDIT_GROUP_REQUEST,
    ActionType.EDIT_GROUP_RESPONSE,
    ActionType.EDIT_GROUP_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupChat',
    ActionType.GROUP_CHAT_REQUEST,
    ActionType.GROUP_CHAT_RESPONSE,
    ActionType.GROUP_CHAT_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupCounts',
    ActionType.GROUP_COUNTS_REQUEST,
    ActionType.GROUP_COUNTS_RESPONSE,
    ActionType.GROUP_COUNTS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupPrivates',
    ActionType.GROUP_PRIVATES_REQUEST,
    ActionType.GROUP_PRIVATES_RESPONSE,
    ActionType.GROUP_PRIVATES_ERROR,
  ),
  ...firestoreServerApiHandler(
    'sendGroupChat',
    ActionType.SEND_GROUP_CHAT_REQUEST,
    ActionType.SEND_GROUP_CHAT_RESPONSE,
    ActionType.SEND_GROUP_CHAT_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupCompCounts',
    ActionType.GROUP_COMPETITION_COUNTS_REQUEST,
    ActionType.GROUP_COMPETITION_COUNTS_RESPONSE,
    ActionType.GROUP_COMPETITION_COUNTS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupQuestions',
    ActionType.GROUP_QUESTIONS_REQUEST,
    ActionType.GROUP_QUESTIONS_RESPONSE,
    ActionType.GROUP_QUESTIONS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'groupLiveLeaderboards',
    ActionType.GROUP_LIVE_LEADERBOARDS_REQUEST,
    ActionType.GROUP_LIVE_LEADERBOARDS_RESPONSE,
    ActionType.GROUP_LIVE_LEADERBOARDS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'vendorCompCounts',
    ActionType.VENDOR_COMPETITION_COUNTS_REQUEST,
    ActionType.VENDOR_COMPETITION_COUNTS_RESPONSE,
    ActionType.VENDOR_COMPETITION_COUNTS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'vendorQuestions',
    ActionType.VENDOR_QUESTIONS_REQUEST,
    ActionType.VENDOR_QUESTIONS_RESPONSE,
    ActionType.VENDOR_QUESTIONS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'vendorLiveLeaderboards',
    ActionType.VENDOR_LIVE_LEADERBOARDS_REQUEST,
    ActionType.VENDOR_LIVE_LEADERBOARDS_RESPONSE,
    ActionType.VENDOR_LIVE_LEADERBOARDS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'vendorNews',
    ActionType.VENDOR_NEWS_REQUEST,
    ActionType.VENDOR_NEWS_RESPONSE,
    ActionType.VENDOR_NEWS_ERROR,
  ),
  ...firestoreServerApiHandler(
    'announcements',
    ActionType.ANNOUNCEMENTS_REQUEST,
    ActionType.ANNOUNCEMENTS_RESPONSE,
    ActionType.ANNOUNCEMENTS_ERROR,
  ),
};
